<nav class="sidebar sidebar-offcanvas" id="sidebar" [ngClass]="overall_approve ? 'teamappr_show' : 'teamappr_none'">
  <ul class="nav">
    <li class="nav-item">
      <a class="nav-link" (click)="clickedMenu($event,'profile')"
        [ngClass]="selectedItem === 'profile' ? 'active' : 'none'" id="nav-link1" routerLink="profile/profile"
        routerLinkActive="active">
        <i class="fa fa-user-circle-o menu-icon"></i>
        <span class="menu-title">My Profile</span>
      </a>
    </li>
    <li class="nav-item" [ngClass]="submenuParentActive === 'Attendance' ? 'active' : 'none'">
      <a class="nav-link" data-toggle="collapse" (click)="clickedMenu1($event,'Attendance')" id="nav-link2"
        routerLink="attendance/dailyattedance">
        <i class="menu-icon fa fa-address-book"></i>
        <span class="menu-title">Attendance</span>
        <i class="menu-arrow"></i>
      </a>
      <div class="collapse" [ngbCollapse]="parentId !== 'nav-link2'" id="Attendance">
        <ul class="nav flex-column sub-menu">
          <li class="nav-item">
            <a class="nav-link" routerLink="attendance/dailyattedance" routerLinkActive="active"
              #dropdownLink="routerLinkActive">Daily Attendance
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" routerLink="attendance/attendance" routerLinkActive="active"
              #buttonsLink="routerLinkActive">Monthly Attendance
            </a>
          </li>
        </ul>
      </div>
    </li>
    <li class="nav-item" [ngClass]="submenuParentActive === 'Apply' ? 'active' : 'none'">
      <a class="nav-link" (click)="clickedMenu1($event,'Apply')" id="nav-link3" routerLink="leave/leaveapply">
        <i class="menu-icon fa fa-pencil-square-o"></i>
        <span class="menu-title">Apply</span>
        <i class="menu-arrow"></i>
      </a>
      <div class="collapse" [ngbCollapse]="parentId !== 'nav-link3'" id="Apply">
        <ul class="nav flex-column sub-menu">
          <li class="nav-item">
            <a class="nav-link" routerLink="leave/leaveapply" routerLinkActive="active"
              #buttonsLink="routerLinkActive">Leave
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" routerLink="wfh/wfhapply" routerLinkActive="active" #dropdownLink="routerLinkActive">WFH
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" routerLink="permission/permissionapply" routerLinkActive="active"
              #typographyLink="routerLinkActive">Permission
            </a>
          </li>
          <li *ngIf="on_compoff_apply==='true'" class="nav-item">
            <a class="nav-link" routerLink="compoff/compoffapply" routerLinkActive="active"
              #typographyLink="routerLinkActive">Compoff
            </a>
          </li>
          <li *ngIf="on_duty_approve===true" class="nav-item">
            <a class="nav-link" routerLink="onduty/ondutyapply" routerLinkActive="active"
              #typographyLink="routerLinkActive">Onduty
            </a>
          </li>
        </ul>
      </div>
    </li>
    <li class="nav-item">
      <a class="nav-link" (click)="clickedMenu($event,'Policies')"
        [ngClass]="selectedItem === 'Policies' ? 'active' : 'none'" id="nav-link6" routerLink="leave/leavepolicy"
        routerLinkActive="active">
        <i class="menu-icon fa fa-file-o"></i>
        <span class="menu-title">Policies</span>
      </a>
    </li>
    <li class="nav-item">
      <a class="nav-link" (click)="clickedMenu($event,'Documents')"
        [ngClass]="selectedItem === 'Documents' ? 'active' : 'none'" id="nav-link15" routerLink="documents/paysliplist"
        routerLinkActive="active">
        <i class="menu-icon fa fa-file-o"></i>
        <span class="menu-title">Documents</span>
      </a>
    </li>
    <li class="nav-item">
      <a class="nav-link" (click)="clickedMenuAdp($event,'Payroll-portal')"
        [ngClass]="selectedItem === 'Payroll-portal' ? 'active' : 'none'" id="nav-link12"
        routerLinkActive="active">
        <i class="menu-icon fa fa-file-o"></i>
        <span class="menu-title">Payroll portal</span>
      </a>
    </li>
    <li class="nav-item">
      <a class="nav-link" (click)="clickedMenu($event,'Holidays List')"
        [ngClass]="selectedItem === 'Holidays List' ? 'active' : 'none'" id="nav-link7"
        routerLink="holidaylist/holidaylist" routerLinkActive="active">
        <i class="menu-icon fa fa-calendar"></i>
        <span class="menu-title">Holidays List</span>
      </a>
    </li>
    <li class="nav-item">
      <a class="nav-link" (click)="clickedMenu($event,'Support Team Contact')"
        [ngClass]="selectedItem === 'Support Team Contact' ? 'active' : 'none'" id="nav-link8"
        routerLink="support/supportcontact" routerLinkActive="active">
        <i class="menu-icon fa fa-users"></i>
        <span class="menu-title">Support Team Contact</span>
      </a>
    </li>
    <li class="nav-item">
      <a class="nav-link" *ngIf="tds==='true'" (click)="clickedMenu($event,'Form-12BB')"
        [ngClass]="selectedItem === 'Form-12BB' ? 'active' : 'none'" id="nav-link12" routerLink="tds/tdsform"
        routerLinkActive="active">
        <i class="menu-icon fa fa-file-o"></i>
        <span class="menu-title">Form-12BB</span>
      </a>
    </li>
    <li class="nav-item userhide" *ngIf="overall_approve===true">
      <a class="nav-link" (click)="clickedMenu1($event,'Team')" id="nav-link5"  [ngClass]="submenuParentActive === 'Team' ? 'active' : 'none'" 
        routerLink="team/teaminfo">
        <i class="menu-icon fa fa-users"></i>
        <span class="menu-title">Team</span>
        <i class="menu-arrow"></i>
      </a>
      <div class="collapse" [ngbCollapse]="parentId !== 'nav-link5'" id="Team">
        <ul class="nav flex-column sub-menu">
          <li class="nav-item">
            <a class="nav-link" routerLink="team/teaminfo" routerLinkActive="active"
              #dropdownLink="routerLinkActive">Team
              Info</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" routerLink="team/teamattendance" routerLinkActive="active"
              #buttonsLink="routerLinkActive">Team Attendance</a>
          </li>
        </ul>
      </div>
    </li>
    <li class="nav-item userhide" *ngIf="overall_approve===true">
      <!-- <a class="nav-link" (click)="clickedMenu1($event,'Approval')" id="nav-link4" [ngClass]="submenuParentActive === 'Approval' ? 'active' : 'none'" 
        routerLink="leave/leaveapprovelist"
        > -->
        <a class="nav-link" (click)="clickedMenu1($event,'Approval')" id="nav-link4" [ngClass]="submenuParentActive === 'Approval' ? 'active' : 'none'" 
        >
        <i class="menu-icon fa fa-check-circle-o"></i>
        <span class="menu-title">Approval
          <span *ngIf="total_counts != 0" class="badge badge-pill badge-warning">{{ total_counts }}</span>
          <span *ngIf="total_counts === 0"> </span>
        </span>
        <i class="menu-arrow"></i>
      </a>
      <div class="collapse" [ngbCollapse]="parentId !== 'nav-link4'" id="Approval">
        <ul class="nav flex-column sub-menu">
          <li class="nav-item">
            <a class="nav-link" routerLink="leave/leaveapprovelist" routerLinkActive="active"
              #buttonsLink="routerLinkActive">Leave
              <span *ngIf="leave_count != 0" class="badge badge-pill badge-warning">{{leave_count }}</span>
              <span *ngIf="leave_count === 0"> </span></a>
              <!-- <a class="nav-link" href="{{backendUrl}}/essportal#/leaveapprovelist" target="_blank" routerLinkActive="active"
              #buttonsLink="routerLinkActive">Leave
              <span *ngIf="leave_count != 0" class="badge badge-pill badge-warning">{{leave_count }}</span>
              <span *ngIf="leave_count === 0"> </span></a> -->
          </li>
          <li class="nav-item">
            <a class="nav-link" routerLink="wfh/wfhapprovelist" routerLinkActive="active"
              #dropdownLink="routerLinkActive">WFH
              <span *ngIf="wfh_count != 0" class="badge badge-pill badge-warning">{{wfh_count }}</span>
              <span *ngIf="wfh_count === 0"> </span>
            </a>
            <!-- <a class="nav-link" href="{{backendUrl}}/essportal#/wfhapprovelist" target="_blank"  routerLinkActive="active"
              #dropdownLink="routerLinkActive">WFH
              <span *ngIf="wfh_count != 0" class="badge badge-pill badge-warning">{{wfh_count }}</span>
              <span *ngIf="wfh_count === 0"> </span>
            </a> -->
          </li>
          <li class="nav-item">
            <a class="nav-link" routerLink="permission/permapprovelist" routerLinkActive="active"
              #typographyLink="routerLinkActive">Permission
              <span *ngIf="permission_count != 0" class="badge badge-pill badge-warning">{{permission_count }}</span>
              <span *ngIf="permission_count === 0"> </span></a>
              <!-- <a class="nav-link" href="{{backendUrl}}/essportal#/permapprovelist" target="_blank"  routerLinkActive="active"
              #typographyLink="routerLinkActive">Permission
              <span *ngIf="permission_count != 0" class="badge badge-pill badge-warning">{{permission_count }}</span>
              <span *ngIf="permission_count === 0"> </span></a> -->
          </li>
          <li class="nav-item">
            <a class="nav-link"   routerLink="compoff/compoffapprovelist" routerLinkActive="active"
              #typographyLink="routerLinkActive">Compoff
              <span *ngIf="compoff_count!= 0" class="badge badge-pill badge-warning">{{ compoff_count }}</span>
              <span *ngIf="compoff_count === 0"> </span></a>
              <!-- <a class="nav-link" href="{{backendUrl}}/essportal#/compoffapprovelist" target="_blank"   routerLinkActive="active"
              #typographyLink="routerLinkActive">Compoff
              <span *ngIf="compoff_count!= 0" class="badge badge-pill badge-warning">{{ compoff_count }}</span>
              <span *ngIf="compoff_count === 0"> </span></a> -->
          </li>
          <li class="nav-item">
            <a class="nav-link" routerLink="onduty/ondutyapprovelist" routerLinkActive="active"
              #typographyLink="routerLinkActive">Onduty
              <span *ngIf="onduty_count!= 0" class="badge badge-pill badge-warning">{{ onduty_count }}</span>
              <span *ngIf="onduty_count === 0"> </span></a>
              <!-- <a class="nav-link" href="{{backendUrl}}/essportal#/ondutyapprovelist"  target="_blank"  routerLinkActive="active"
              #typographyLink="routerLinkActive">Onduty
              <span *ngIf="onduty_count!= 0" class="badge badge-pill badge-warning">{{ onduty_count }}</span>
              <span *ngIf="onduty_count === 0"> </span></a> -->
          </li>
        </ul>
      </div>
    </li>
    <!--Letter Approvals-->
    <li class="nav-item userhide" [ngClass]="submenuParentActive === 'Letter-Approvals' ? 'active' : 'none'">
      <a class="nav-link" (click)="clickedMenu1($event,'Letter-Approvals')" id="nav-link10"
        *ngIf="offer_letter_approve===true || offer_letter_approve===true || relieve_approve===true">
        <i class="menu-icon fa fa-check-circle-o"></i>
        <span class="menu-title">Letter Approvals</span>
        <i class="menu-arrow"></i>
      </a>
      <div class="collapse" [ngbCollapse]="parentId !== 'nav-link10'" id="Letter-Approvals">
        <ul class="nav flex-column sub-menu">
          <li *ngIf="offer_letter_approve===true" class="nav-item">
            <a class="nav-link" routerLink="letter/offerLetterlist" routerLinkActive="active"
              #typographyLink="routerLinkActive">Offer Letter</a>
          </li>
          <li *ngIf="appraisal_letter_approve === true" class="nav-item">
            <a class="nav-link" routerLink="letter/appraisallist" routerLinkActive="active"
              #typographyLink="routerLinkActive">Appraisal Letter
            </a>
          </li>
          <li *ngIf="relieve_approve===true" class="nav-item">
            <a class="nav-link" routerLink="letter/relievinglist" routerLinkActive="active"
              #typographyLink="routerLinkActive">Relieving Letter
            </a>
          </li>
        </ul>
      </div>
    </li>

     <!--Conference Room-->
     <li class="nav-item" [ngClass]="submenuParentActive === 'conference-room' ? 'active' : 'none'">
      <a class="nav-link" (click)="clickedMenu1($event,'conference-room')" id="nav-conference">
        <i class="menu-icon fa fa-check-circle-o"></i>
        <span class="menu-title">Conference Room</span>
        <i class="menu-arrow"></i>
      </a>
      <div class="collapse" [ngbCollapse]="parentId !== 'nav-conference'" id="conference-room">
        <ul class="nav flex-column sub-menu">
          <li class="nav-item">
            <a class="nav-link" routerLink="conference/rooms" routerLinkActive="active"
              #typographyLink="routerLinkActive">Listing</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" routerLink="conference/booking" routerLinkActive="active"
              #typographyLink="routerLinkActive">Book a Room</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" routerLink="conference/listing" routerLinkActive="active"
              #typographyLink="routerLinkActive">Booking History</a>
          </li>
        </ul>
      </div>
    </li>
    <li class="nav-item" [ngClass]="submenuParentActive === 'Tickets' ? 'active' : 'none'">
      <a class="nav-link" (click)="clickedMenu1($event,'Tickets')" id="nav-link21" routerLink="ticket/raiseticket">
        <i class="menu-icon icon-user"></i>
        <span class="menu-title">Ticket</span>
        <i class="menu-arrow"></i>
      </a>
      <div class="collapse" [ngbCollapse]="parentId !== 'nav-link21'" id="Tickets">
        <ul class="nav flex-column sub-menu">
          <li *ngIf="ticketing_dashboard ==='true'" class="nav-item">
            <a class="nav-link" routerLink="ticket/dashboard" routerLinkActive="active"
              #typographyLink="routerLinkActive">
              Dashboard
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" routerLink="ticket/raiseticket" routerLinkActive="active"
              #typographyLink="routerLinkActive">
              Raise Ticket
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" routerLink="ticket/ticketingApprovalList" routerLinkActive="active"
              *ngIf="overall_approve===true || ticket_approve==true" #typographyLink="routerLinkActive">
              Approval
              <span *ngIf="ticket != 0" class="badge badge-pill badge-warning">{{ ticket }}</span>
              <span *ngIf="ticket === 0"> </span>
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" routerLink="ticket/ticketreport" routerLinkActive="active"
              *ngIf="overall_approve===true || ticket_approve==true || ticket_report==true" #typographyLink="routerLinkActive">Ticket Report
            </a>
          </li>
        </ul>
      </div>
    </li>
    <!--Resignation-->
    <li class="nav-item" [ngClass]="submenuParentActive === 'Resignation' ? 'active' : 'none'">
      <a class="nav-link" (click)="clickedMenu1($event,'Resignation')" id="nav-link9"
        routerLink="resignation/resignationapply">
        <i class="menu-icon icon-user"></i>
        <span class="menu-title">Resignation</span>
        <i class="menu-arrow"></i>
      </a>
      <div class="collapse" [ngbCollapse]="parentId !== 'nav-link9'" id="Resignation">
        <ul class="nav flex-column sub-menu">
          <li class="nav-item">
            <a class="nav-link" routerLink="resignation/resignationapply" routerLinkActive="active"
              #typographyLink="routerLinkActive">
              Apply
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" routerLink="resignation/resignationapprove" routerLinkActive="active"
              *ngIf="overall_approve===true" #typographyLink="routerLinkActive">
              Approve
              <span *ngIf="resi_count != 0 " class="badge badge-pill badge-warning">{{resi_count}}</span>
              <span *ngIf="resi_count === 0"> </span>
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" routerLink="resignation/noDueApprovalList" routerLinkActive="active"
              *ngIf="nodue===true" #typographyLink="routerLinkActive">NoDue Approve
              <span *ngIf="noDueList != 0" class="badge badge-pill badge-warning">{{noDueList }}</span>
              <span *ngIf="noDueList === 0"> </span></a>
          </li>
        </ul>
      </div>
    </li>
    <!-- Recruitment -->
    <li *ngIf="recruitment==='true'" class="nav-item" [ngClass]="submenuParentActive === 'recruitment' ? 'active' : 'none'">
      <a class="nav-link" (click)="clickedMenu1($event,'recruitment')" id="nav-link10"
        routerLink="recruitment/recruitment_details">
        <i class="menu-icon fa fa-check-circle-o"></i>
        <span class="menu-title">Recruitments</span>
        <i class="menu-arrow"></i>
      </a>
      <div class="collapse" [ngbCollapse]="parentId !== 'nav-link10'" id="recruitment">
        <ul class="nav flex-column sub-menu">
          <li class="nav-item">
            <a class="nav-link" (click)="clickedMenu($event,'Recruitment_details')"
              routerLink="recruitment/recruitment_details" routerLinkActive="active" #typegraphyLink="routerLinkActive">
              Recruitment Details
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" (click)="clickedMenu($event,'Support')" id="nav-link10" target="_blank"
              href="https://erpstaging.optisolbusiness.com/web#action=473&model=hr.job_posting&view_type=list&menu_id=241">
              <span class="menu-title">Recruitment</span>
            </a>
          </li>
        </ul>
      </div>
    </li>
    <!-- Employee Data -->
    <li *ngIf="emp_data_user===true" class="nav-item">
      <a class="nav-link" (click)="clickedMenu($event,'employeedata')"
        [ngClass]="selectedItem === 'employeedata' ? 'active' : 'none'" id="nav-link15" routerLink="employeedata/employeedata"
        routerLinkActive="active">
        <i class="menu-icon fa fa-file-o"></i>
        <span class="menu-title">Employee Data</span>
      </a>
    </li>
    <!-- Employee Details -->
    <li *ngIf="dashboard_show==='true'" class="nav-item">
      <a class="nav-link" (click)="clickedMenu($event,'employeedetails')"
        [ngClass]="selectedItem === 'employeedetails' ? 'active' : 'none'" id="nav-link15" routerLink="employeedetails/employeedetails"
        routerLinkActive="active">
        <i class="menu-icon fa fa-file-o"></i>
        <span class="menu-title">Employee Details</span>
      </a>
    </li>
    <!-- <li *ngIf="offer_letter_approve===true" class="nav-item">
      <a class="nav-link" (click)="clickedMenu($event,'Support')" id="nav-link6" target="_blank"
        href="https://erpstaging.optisolbusiness.com/web#action=99&active_id=mailbox_inbox&menu_id=78">
        <i class="menu-icon fa fa-file-o"></i>
        <span class="menu-title">Odoo</span>
      </a>
    </li> -->
  </ul>
</nav>